<template>
  <div class="module-box-wrapper"
       :style="{'background':theme.worktop_model_background ? theme.worktop_model_background : '#fff'}">
    <div class="title-wrapper">
      <div>
        <span class="title"
              :style="{'color':'#475F7B'}">{{appModule.modelAlias}}</span>
        <slot name="search"></slot>
        <div class="dropdownMenuWrapper">
          <el-dropdown trigger="click"
                       @command="moreHandle">
            <span class="el-dropdown-link">
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ type: 1, id: 1, dialogShowFlag: '' }">编辑模块</el-dropdown-item>
              <el-dropdown-item :command="{ type: 2, id: 2, dialogShowFlag: ''  }">隐藏模块</el-dropdown-item>
              <el-dropdown-item :command="{ type: 3, dialogShowFlag: '' }"
                                class="group-line">添加模块</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <slot></slot>
    <module-dialog v-model="modelDialogVisible"
                   :isEdit="isEdit"
                   :appModule="appModule"
                   :updateList="updateList" />
  </div>
</template>

<script>
import ModuleDialog from './ModuleDialog'
import { deleteModelURL } from '../api'
export default {
  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  inject: ['updateList'],
  data () {
    return {
      modelDialogVisible: false,
      isEdit: false
    }
  },
  computed: {
    theme () {
      return this.$store.state.theme
    }
  },
  components: {
    ModuleDialog
  },
  created () {
  },
  methods: {
    moreHandle (data) {
      const type = data.type
      if (type === 1) {
        // 编辑
        this.modelDialogVisible = true
        this.isEdit = true
      } else if (type === 2) {
        // 隐藏
        this.deleteModule()
      } else if (type === 3) {
        // 新增
        this.isEdit = false
        this.modelDialogVisible = true
      }
    },
    // 删除模块
    deleteModule () {
      let _this_ = this
      let postData = {
        id: _this_.appModule.id,
        classification: _this_.appModule.classification
      }
      this.$axios.post(deleteModelURL, postData).then(res => {
        if (res.status === 100) {
          // 更新主页的list
          _this_.updateList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.module-box-wrapper {
  border-radius: 5px;
}
.title-wrapper {
  width: 100%;
  height: 48px;
  position: relative;
  text-align: left;
  border-radius: 4px;
  .title {
    display: inline-block;
    vertical-align: middle;
    height: 48px;
    line-height: 48px;
    text-align: left;
    font-size: 16px;
    padding-left: 18px;
    font-weight: 600;
    border-radius: 5px;
  }
  .dropdownMenuWrapper {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background: url(./images/moreIcon.png) no-repeat;
    cursor: pointer;
  }
  .el-dropdown-link {
    display: block;
    cursor: pointer;
    // color: #409eff;
    width: 100%;
    height: 100%;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
  ::v-deep .el-dropdown {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    height: 100%;
    width: 100%;
    text-indent: 1000px;
  }
  .foot-btn {
    margin-right: 20px;
  }
  .line {
    position: relative;
    top: -25px;
    width: 100%;
    height: 1px;
    background: #eaeaea;
  }
}
.group-line {
  border-top: 1px solid #eaeaea;
}
</style>
