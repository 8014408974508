var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "module-box-wrapper",
      style: {
        background: _vm.theme.worktop_model_background
          ? _vm.theme.worktop_model_background
          : "#fff",
      },
    },
    [
      _c("div", { staticClass: "title-wrapper" }, [
        _c(
          "div",
          [
            _c("span", { staticClass: "title", style: { color: "#475F7B" } }, [
              _vm._v(_vm._s(_vm.appModule.modelAlias)),
            ]),
            _vm._t("search"),
            _c(
              "div",
              { staticClass: "dropdownMenuWrapper" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: { command: _vm.moreHandle },
                  },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: {
                              command: { type: 1, id: 1, dialogShowFlag: "" },
                            },
                          },
                          [_vm._v("编辑模块")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            attrs: {
                              command: { type: 2, id: 2, dialogShowFlag: "" },
                            },
                          },
                          [_vm._v("隐藏模块")]
                        ),
                        _c(
                          "el-dropdown-item",
                          {
                            staticClass: "group-line",
                            attrs: { command: { type: 3, dialogShowFlag: "" } },
                          },
                          [_vm._v("添加模块")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
      _vm._t("default"),
      _c("module-dialog", {
        attrs: {
          isEdit: _vm.isEdit,
          appModule: _vm.appModule,
          updateList: _vm.updateList,
        },
        model: {
          value: _vm.modelDialogVisible,
          callback: function ($$v) {
            _vm.modelDialogVisible = $$v
          },
          expression: "modelDialogVisible",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }